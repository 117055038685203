:root {
  --primary-colour: #471aa0;
  --font-colour: white;
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
  color: black;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  overflow: hidden;
}

.header {
  height: 15vh;
  margin: 0;
  display: flex;
  margin: 0 auto;
  position: relative;
  align-items: center;
  justify-content: space-between;
  box-shadow: black;
  padding: 20px;
}

@media screen and (min-width: 1024px) {
  .header {
    max-width: 960px;
  }
}

/* Header Section */

.intialsIcon {
  height: 5em;
  border-radius: 5px;
}

.header-text-container {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 20px;
  font-weight: bold;
}
.header-text-container a {
  text-decoration: none;
  color: black;
}

.header-text-container a {
  cursor: pointer;
}

.header-text-container a:hover {
  color: var(--primary-colour);
}

/* ====== */

/* AboutMe.css */

.aboutme-section {
  display: flex;
  justify-content: center;
}

.myname {
  color: var(--primary-colour);
}

.techName {
  color: var(--primary-colour);
  font-weight: bold;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 20px;
  width: 50vw;
  height: 70vh;
}

.aboutspecs-container {
  text-align: center;
}

.aboutspecs-container h1 {
  color: black;
  font-weight: 700;
  font-size: 3.3em;
}

.aboutme-image {
  min-width: 300px;
  border: solid 5px var(--primary-colour);
  border-radius: 50%;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.icon {
  font-size: 50px;
  color: black;
  transition: color 0.2s;
}

.icon:hover {
  color: var(--primary-colour);
}

/* ==== Utilities ==== */

/* CSS */

.aboutspecs-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* CSS */
.buttonStyle {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
  color: black;
}

.buttonStyle:after {
  content: '';
  background-color: #e6705a;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.buttonStyle:hover:after {
  top: 0px;
  left: 0px;
}

.buttonStyleResume {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid var(--primary-colour);
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
  background-color: white;
  color: black;
}

.buttonStyleResume:hover {
  background-color: var(--primary-colour);
  color: white;
}

.a-style {
  text-decoration: none;
  color: black;
}

.a-style:hover {
  color: white;
}

@media (min-width: 768px) {
  .buttonStyle {
    padding: 13px 50px 13px;
  }
}

@media (min-width: 768px) {
  .buttonStyle {
    padding: 13px 50px 13px;
  }
}

.section-separator {
  width: 100%;
  height: 50px;
  background-color: #e6705a;
  text-align: center;
}

/* Skills Section */

.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  width: 100vw;
  background-color: white;
}

.skill-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.skill-h3 {
  color: var(--primary-colour);
}

.skill-description-card {
  width: 50%;
  padding: 30px;
  border: solid 1px grey;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 5px;
}
.skill-description-card h2 {
  color: black;
  font-size: 2.2rem;
  text-align: center;
}

.skill-list {
  list-style: none;
  text-align: center;
  padding: 0;
}

.skill-icons-Names-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.skills-divs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-shadow: 2px 2px 4px var(--primary-colour);
}

/* Projects Section */

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.projects-container h2 {
  color: black;
  font-size: 2.2rem;
}

.project-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 800px;
  margin-top: 20px;
}

.project-image {
  width: 5000px;
}

.project-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.project-description {
  letter-spacing: 2px;
  width: 90%;
}

.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.project-content h3 {
  margin-top: 0;
}

.project-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.project-button-style {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
  color: black;
  background-color: white;
}

.project-button-style:hover {
  background-color: var(--primary-colour);
  color: white;
}

/* MyProjects.css */
.project-card {
  position: relative;
  overflow: hidden;
  max-width: 500px; /* Set your desired card width */
  height: auto;
  margin: 20px;
  transition: transform 0.3s;
  border-radius: 5px;
}

.project-image {
  position: relative;
  overflow: hidden;
  width: 100%; /* Update this line to make the image container full-width */
  height: auto;
}

.project-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.project-content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Update this line to make the content container full-width */
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Adjust the background color as needed */
  color: #fff; /* Text color for description and tags */
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s;
}

.project-card:hover .project-content-container {
  opacity: 1;
}

.project-card:hover img {
  transform: scale(1.05); /* Adjust the scale as needed */
}

/* Contact Componenet */

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  background-color: var(--primary-colour);
  padding: 50px;
  height: 50vh;
}

.contact-container h2 {
  color: white;
  font-weight: 700;
  font-size: 2.2em;
}

.contact-image {
  width: 300px;
  height: auto;
  max-width: 100%;
  border: 5px solid #e6705a;
  border-radius: 50%;
  margin-bottom: 20px;
}

.contact-story {
  line-height: 1.5;
  font-size: 1.2rem;
  text-align: center;
  width: 40vw;
}

.contact-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.contact-icon {
  margin: 0 10px;
}

.contact-icon a {
  color: white;
  font-size: 50px;
  text-decoration: none;
}

.contact-icon a:hover {
  color: black;
}

/* Footer Container */
.footer-container {
  height: 10vh;
  background-color: var(--primary-colour);
}

/* Media Queries */

@media screen and (min-width: 768px) {
  .card {
    width: 500px;
  }

  .aboutme-image {
    width: 200px;
    border-width: 3px;
  }
  /* Continue adjusting styles for other contact elements */
}
